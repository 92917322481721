import { ChainId, Currency, Token, currencyEquals, JSBI, Price } from '@uniswap/sdk'
import { useMemo } from 'react'
import { MATIC_USDC, MATIC_WETH } from '../constants'
import { PairState, usePairs } from '../data/Reserves'
import { useActiveWeb3React } from '../hooks'
import { wrappedCurrency } from './wrappedCurrency'

/**
 * Returns the price in USDC of the input currency
 * @param currency currency to compute the USDC price of
 */
export default function useUSDCPrice(currency?: Currency): Price | undefined {
  const { chainId } = useActiveWeb3React()
  const wrapped = wrappedCurrency(currency, chainId)
  const tokenPairs: [Currency | undefined, Currency | undefined][] = useMemo(
    () => [
      [
        chainId && wrapped && currencyEquals(MATIC_WETH, wrapped) ? undefined : currency,
        chainId ? MATIC_WETH : undefined
      ],
      [wrapped?.equals(MATIC_USDC) ? undefined : wrapped, chainId === ChainId.MATIC ? MATIC_USDC : undefined],
      [chainId ? MATIC_WETH : undefined, chainId === ChainId.MATIC ? MATIC_USDC : undefined]
    ],
    [chainId, currency, wrapped]
  )
  const [[ethPairState, ethPair], [usdcPairState, usdcPair], [usdcEthPairState, usdcEthPair]] = usePairs(tokenPairs)

  return useMemo(() => {
    if (!currency || !wrapped || !chainId) {
      return undefined
    }
    // handle weth/eth
    if (wrapped.equals(MATIC_WETH)) {
      if (usdcPair) {
        const price = usdcPair.priceOf(MATIC_WETH)
        return new Price(currency, MATIC_USDC, price.denominator, price.numerator)
      } else {
        return undefined
      }
    }
    // handle usdc
    if (wrapped.equals(MATIC_USDC)) {
      return new Price(MATIC_USDC, MATIC_USDC, '1', '1')
    }

    const ethPairETHAmount = ethPair?.reserveOf(MATIC_WETH)
    const ethPairETHUSDCValue: JSBI =
      ethPairETHAmount && usdcEthPair ? usdcEthPair.priceOf(MATIC_WETH).quote(ethPairETHAmount).raw : JSBI.BigInt(0)

    // all other tokens
    // first try the usdc pair
    if (
      usdcPairState === PairState.EXISTS &&
      usdcPair &&
      usdcPair.reserveOf(MATIC_USDC).greaterThan(ethPairETHUSDCValue)
    ) {
      const price = usdcPair.priceOf(wrapped)
      return new Price(currency, MATIC_USDC, price.denominator, price.numerator)
    }
    if (ethPairState === PairState.EXISTS && ethPair && usdcEthPairState === PairState.EXISTS && usdcEthPair) {
      if (usdcEthPair.reserveOf(MATIC_USDC).greaterThan('0') && ethPair.reserveOf(MATIC_WETH).greaterThan('0')) {
        const ethUsdcPrice = usdcEthPair.priceOf(MATIC_USDC)
        const currencyEthPrice = ethPair.priceOf(MATIC_WETH)
        const usdcPrice = ethUsdcPrice.multiply(currencyEthPrice).invert()
        return new Price(currency, MATIC_USDC, usdcPrice.denominator, usdcPrice.numerator)
      }
    }
    return undefined
  }, [chainId, currency, ethPair, ethPairState, usdcEthPair, usdcEthPairState, usdcPair, usdcPairState, wrapped])
}

export function useUSDCPrices(currencies?: Currency[]): (Price | undefined)[] | undefined {
  const { chainId } = useActiveWeb3React()
  const wrappedCurrencies = currencies?.map(currency => wrappedCurrency(currency, chainId))
  const tokenPairs: [Currency | undefined, Currency | undefined][][] | undefined = useMemo(
    () =>
      currencies?.map((currency, i) => {
        const wrapped: Token | undefined = wrappedCurrencies?.[i]
        return [
          [
            chainId && wrapped && currencyEquals(MATIC_WETH, wrapped) ? undefined : currency,
            chainId ? MATIC_WETH : undefined
          ],
          [wrapped?.equals(MATIC_USDC) ? undefined : wrapped, chainId === ChainId.MATIC ? MATIC_USDC : undefined],
          [chainId ? MATIC_WETH : undefined, chainId === ChainId.MATIC ? MATIC_USDC : undefined]
        ]
      }),
    [chainId, currencies, wrappedCurrencies]
  )

  const mergedTokenPairs = useMemo(() => ([] as any[]).concat(...tokenPairs), [tokenPairs])

  const tokenPairsState = usePairs(mergedTokenPairs)

  //const [[ethPairState, ethPair], [usdcPairState, usdcPair], [usdcEthPairState, usdcEthPair]] = usePairs(
  return useMemo(() => {
    if (!currencies || !wrappedCurrencies || !chainId) {
      return undefined
    }

    return currencies.map((currency, i) => {
      if (!currency || !wrappedCurrencies[i]) {
        return undefined
      }

      const wrapped: Token = wrappedCurrencies[i] as Token
      const [ethPairState, ethPair] = tokenPairsState[i * 3]
      const [usdcPairState, usdcPair] = tokenPairsState[i * 3 + 1]
      const [usdcEthPairState, usdcEthPair] = tokenPairsState[i * 3 + 2]

      // handle weth/eth
      if (wrapped.equals(MATIC_WETH)) {
        if (usdcPair) {
          const price = usdcPair.priceOf(MATIC_WETH)
          return new Price(currency, MATIC_USDC, price.denominator, price.numerator)
        } else {
          return undefined
        }
      }
      // handle usdc
      if (wrapped.equals(MATIC_USDC)) {
        return new Price(MATIC_USDC, MATIC_USDC, '1', '1')
      }

      const ethPairETHAmount = ethPair?.reserveOf(MATIC_WETH)
      const ethPairETHUSDCValue: JSBI =
        ethPairETHAmount && usdcEthPair ? usdcEthPair.priceOf(MATIC_WETH).quote(ethPairETHAmount).raw : JSBI.BigInt(0)

      // all other tokens
      // first try the usdc pair
      if (
        usdcPairState === PairState.EXISTS &&
        usdcPair &&
        usdcPair.reserveOf(MATIC_USDC).greaterThan(ethPairETHUSDCValue)
      ) {
        const price = usdcPair.priceOf(wrapped)
        return new Price(currency, MATIC_USDC, price.denominator, price.numerator)
      }
      if (ethPairState === PairState.EXISTS && ethPair && usdcEthPairState === PairState.EXISTS && usdcEthPair) {
        if (usdcEthPair.reserveOf(MATIC_USDC).greaterThan('0') && ethPair.reserveOf(MATIC_WETH).greaterThan('0')) {
          const ethUsdcPrice = usdcEthPair.priceOf(MATIC_USDC)
          const currencyEthPrice = ethPair.priceOf(MATIC_WETH)
          const usdcPrice = ethUsdcPrice.multiply(currencyEthPrice).invert()
          return new Price(currency, MATIC_USDC, usdcPrice.denominator, usdcPrice.numerator)
        }
      }
      return undefined
    })
  }, [chainId, currencies, tokenPairsState, wrappedCurrencies])
}
