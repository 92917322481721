import { CurrencyAmount, JSBI, Token, TokenAmount, Pair, Fraction } from '@uniswap/sdk'
import { useMemo } from 'react'
import { UNI, MUST, MATIC_USDC } from '../../constants/index'
import { STAKING_REWARDS_INFO, STAKING_MULTI_REWARDS_INFO } from '../../constants/staking'
import { STAKING_REWARDS_INTERFACE, STAKING_MULTI_REWARDS_INTERFACE } from '../../constants/abis/staking-rewards'
import ERC20_ABI from '../../constants/abis/erc20'
import useUSDCPrice, { useUSDCPrices } from '../../utils/useUSDCPrice'
import { useActiveWeb3React } from '../../hooks'
import { NEVER_RELOAD, useMultipleContractSingleData } from '../multicall/hooks'
import { tryParseAmount } from '../swap/hooks'
import { usePairs } from '../../data/Reserves'
import useCurrentBlockTimestamp from 'hooks/useCurrentBlockTimestamp'

export interface StakingInfo {
  // the address of the reward contract
  stakingRewardAddress: string
  // the tokens involved in this pair
  tokens: [Token, Token]
  // the amount of token currently staked, or undefined if no account
  stakedAmount: TokenAmount
  // the amount of reward token earned by the active account, or undefined if no account
  earnedAmounts: TokenAmount[]
  // the total amount of token staked in the contract
  totalStakedAmount: TokenAmount
  // the amount of token distributed per second to all LPs, constant
  totalRewardRates: TokenAmount[]
  // the current amount of token distributed to the active account per second.
  // equivalent to percent of total supply * reward rate
  rewardRates: TokenAmount[]
  rewardTokens: Token[]
  // when the period ends
  periodFinish: Date | undefined
  // if pool is active
  active: boolean
  // calculates a hypothetical amount of token distributed to the active account per second.

  archived?: boolean
  getHypotheticalRewardRates: (
    stakedAmount: TokenAmount,
    totalStakedAmount: TokenAmount,
    totalRewardRates: TokenAmount[]
  ) => TokenAmount[]

  apy: Fraction | undefined
}

export const STAKING_GENESIS = 1631554709

export const REWARDS_DURATION_DAYS = 7

function compareAPY(a: StakingInfo, b: StakingInfo) {
  const getAPY = (x: StakingInfo) => {
    if (!x.active) {
      return -1
    }
    const v = x && x.apy ? x.apy : new Fraction(JSBI.BigInt(999999))
    return parseFloat(v.toFixed(5))
  }
  return getAPY(b) - getAPY(a)
}

// gets the staking info from the network for the active chain id
export function useStakingSingleInfo(pairToFilterBy?: Pair | null, archived: boolean = false): StakingInfo[] {
  const { chainId, account } = useActiveWeb3React()

  // detect if staking is ended
  const currentBlockTimestamp = useCurrentBlockTimestamp()

  const info = useMemo(
    () =>
      chainId
        ? STAKING_REWARDS_INFO[chainId]?.filter(stakingRewardInfo =>
            pairToFilterBy === undefined
              ? !!stakingRewardInfo.archived === !!archived
              : pairToFilterBy === null
              ? false
              : pairToFilterBy.involvesToken(stakingRewardInfo.tokens[0]) &&
                pairToFilterBy.involvesToken(stakingRewardInfo.tokens[1]) &&
                !!stakingRewardInfo.archived === !!archived
          ) ?? []
        : [],
    [chainId, pairToFilterBy, archived]
  )

  const rewardToken = MUST

  const rewardsAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info])
  const lpAddresses = useMemo(
    () =>
      info.map(({ tokens }) => {
        const pair = new Pair(new TokenAmount(tokens[0], '0'), new TokenAmount(tokens[1], '0'))
        return pair.liquidityToken.address
      }),
    [info]
  )

  const pairs = usePairs(info.map(({ tokens }) => tokens))

  const accountArg = useMemo(() => [account ?? undefined], [account])

  // get all the info from the staking rewards contracts
  const balances = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'balanceOf', accountArg)
  const earnedAmounts = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'earned', accountArg)
  const totalSupplies = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'totalSupply')
  const totalSuppliesOfStakingToken = useMultipleContractSingleData(lpAddresses, ERC20_ABI, 'totalSupply')

  // tokens per second, constants
  const rewardRates = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_REWARDS_INTERFACE,
    'rewardRate',
    undefined,
    NEVER_RELOAD
  )
  const periodFinishes = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_REWARDS_INTERFACE,
    'periodFinish',
    undefined,
    NEVER_RELOAD
  )

  const rewardPrice = useUSDCPrice(rewardToken)
  const mergedTokens = useMemo(() => info.map(({ tokens }) => [tokens[0], tokens[1]]), [info])
  const flattenedTokens = ([] as Token[]).concat(...mergedTokens)
  const tokenPrices = useUSDCPrices(flattenedTokens)

  return useMemo(() => {
    if (!chainId) return []

    return rewardsAddresses.reduce<StakingInfo[]>((memo, rewardsAddress, index) => {
      // these two are dependent on account
      const balanceState = balances[index]
      const earnedAmountState = earnedAmounts[index]

      // these get fetched regardless of account
      const totalSupplyState = totalSupplies[index]
      const rewardRateState = rewardRates[index]
      const periodFinishState = periodFinishes[index]
      const totalSupplyOfStakingTokenState = totalSuppliesOfStakingToken[index]

      if (
        // these may be undefined if not logged in
        !balanceState?.loading &&
        !earnedAmountState?.loading &&
        // always need these
        totalSupplyState &&
        !totalSupplyState.loading &&
        rewardRateState &&
        !rewardRateState.loading &&
        periodFinishState &&
        !periodFinishState.loading
      ) {
        if (
          balanceState?.error ||
          earnedAmountState?.error ||
          totalSupplyState.error ||
          rewardRateState.error ||
          periodFinishState.error
        ) {
          console.error('Failed to load staking rewards info')
          return memo
        }

        // get the LP token
        const tokens = info[index].tokens
        const dummyPair = new Pair(new TokenAmount(tokens[0], '0'), new TokenAmount(tokens[1], '0'))

        // check for account, if no account set to 0
        const stakedAmount = new TokenAmount(dummyPair.liquidityToken, JSBI.BigInt(balanceState?.result?.[0] ?? 0))
        const totalStakedAmount = new TokenAmount(dummyPair.liquidityToken, JSBI.BigInt(totalSupplyState.result?.[0]))
        const totalRewardRate = new TokenAmount(rewardToken, JSBI.BigInt(rewardRateState.result?.[0]))

        const getHypotheticalRewardRates = (
          stakedAmount: TokenAmount,
          totalStakedAmount: TokenAmount,
          totalRewardRates: TokenAmount[]
        ): TokenAmount[] => {
          return [
            new TokenAmount(
              rewardToken,
              JSBI.greaterThan(totalStakedAmount.raw, JSBI.BigInt(0))
                ? JSBI.divide(JSBI.multiply(totalRewardRates[0].raw, stakedAmount.raw), totalStakedAmount.raw)
                : JSBI.BigInt(0)
            )
          ]
        }

        const individualRewardRates = getHypotheticalRewardRates(stakedAmount, totalStakedAmount, [totalRewardRate])

        const periodFinishSeconds = periodFinishState.result?.[0]?.toNumber()
        const periodFinishMs = periodFinishSeconds * 1000

        // compare period end timestamp vs current block timestamp (in seconds)
        const active =
          periodFinishSeconds && currentBlockTimestamp ? periodFinishSeconds > currentBlockTimestamp.toNumber() : true

        const token0 = tokens[0]
        const token1 = tokens[1]

        const [, stakingTokenPair] = pairs[index]

        let apy: Fraction | undefined = undefined

        if (totalSupplyOfStakingTokenState && totalSupplyOfStakingTokenState.result && stakingTokenPair) {
          const totalSupplyOfStakingToken = new TokenAmount(
            stakedAmount.token,
            totalSupplyOfStakingTokenState.result[0]
          )
          const totalSupplyOfStakingTokenInToken0 = new TokenAmount(
            token0,
            JSBI.divide(
              JSBI.multiply(
                JSBI.multiply(totalStakedAmount.raw, stakingTokenPair.reserveOf(token0).raw),
                JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
              ),
              totalSupplyOfStakingToken.raw
            )
          )

          const totalSupplyOfStakingTokenInToken1 = new TokenAmount(
            token1,
            JSBI.divide(
              JSBI.multiply(
                JSBI.multiply(totalStakedAmount.raw, stakingTokenPair.reserveOf(token1).raw),
                JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
              ),
              totalSupplyOfStakingToken.raw
            )
          )

          const token0USD = tokenPrices?.[index * 2]
          const token1USD = tokenPrices?.[index * 2 + 1]

          const valueOfTotalStakedAmountInUSDC =
            totalSupplyOfStakingTokenInToken0 &&
            totalSupplyOfStakingTokenInToken1 &&
            (token0USD?.quote(totalSupplyOfStakingTokenInToken0) || token1USD?.quote(totalSupplyOfStakingTokenInToken1))

          const year = JSBI.BigInt(60 * 60 * 24 * 365)
          const rewardPerYear = new TokenAmount(rewardToken, JSBI.multiply(totalRewardRate.raw, year))
          const valueOfTotalRewardInUSDC = rewardToken && rewardPerYear && rewardPrice?.quote(rewardPerYear)

          if (
            valueOfTotalStakedAmountInUSDC &&
            valueOfTotalStakedAmountInUSDC.greaterThan(JSBI.BigInt(0)) &&
            valueOfTotalRewardInUSDC
          ) {
            apy = valueOfTotalRewardInUSDC.divide(valueOfTotalStakedAmountInUSDC)
          }
        }

        memo.push({
          stakingRewardAddress: rewardsAddress,
          tokens: info[index].tokens,
          periodFinish: periodFinishMs > 0 ? new Date(periodFinishMs) : undefined,
          earnedAmounts: [new TokenAmount(rewardToken, JSBI.BigInt(earnedAmountState?.result?.[0] ?? 0))],
          rewardRates: individualRewardRates,
          totalRewardRates: [totalRewardRate],
          stakedAmount: stakedAmount,
          totalStakedAmount: totalStakedAmount,
          rewardTokens: [rewardToken],
          getHypotheticalRewardRates,
          active,
          archived: archived,
          apy
        })
      }
      return memo
    }, [])
  }, [
    balances,
    chainId,
    currentBlockTimestamp,
    earnedAmounts,
    info,
    periodFinishes,
    rewardRates,
    rewardsAddresses,
    totalSupplies,
    totalSuppliesOfStakingToken,
    rewardToken,
    rewardPrice,
    tokenPrices,
    archived,
    pairs
  ])
}

// gets the staking info from the network for the active chain id
export function useStakingMultiInfo(pairToFilterBy?: Pair | null, archived: boolean = false): StakingInfo[] {
  const { chainId, account } = useActiveWeb3React()

  // detect if staking is ended
  const currentBlockTimestamp = useCurrentBlockTimestamp()

  const info = useMemo(
    () =>
      chainId
        ? STAKING_MULTI_REWARDS_INFO[chainId]?.filter(stakingRewardInfo =>
            pairToFilterBy === undefined
              ? !!stakingRewardInfo.archived === !!archived
              : pairToFilterBy === null
              ? false
              : pairToFilterBy.involvesToken(stakingRewardInfo.tokens[0]) &&
                pairToFilterBy.involvesToken(stakingRewardInfo.tokens[1]) &&
                !!stakingRewardInfo.archived === !!archived
          ) ?? []
        : [],
    [chainId, pairToFilterBy, archived]
  )

  const allRewardTokens = useMemo(() => {
    const rewards = info.map(({ rewards }) => rewards)
    return ([] as Token[]).concat(...rewards)
  }, [info])

  const rewardsAddresses = useMemo(() => info.map(({ stakingMultiRewardsAddress }) => stakingMultiRewardsAddress), [
    info
  ])
  const lpAddresses = useMemo(
    () =>
      info.map(({ tokens }) => {
        const pair = new Pair(new TokenAmount(tokens[0], '0'), new TokenAmount(tokens[1], '0'))
        return pair.liquidityToken.address
      }),
    [info]
  )

  const pairs = usePairs(info.map(({ tokens }) => tokens))

  const accountArg = useMemo(() => [account ?? undefined], [account])

  // get all the info from the staking rewards contracts
  const balances = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_MULTI_REWARDS_INTERFACE,
    'balanceOf',
    accountArg
  )
  const earnedAmounts = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_MULTI_REWARDS_INTERFACE,
    'earned',
    accountArg
  )
  const totalSupplies = useMultipleContractSingleData(rewardsAddresses, STAKING_MULTI_REWARDS_INTERFACE, 'totalSupply')
  const totalSuppliesOfStakingToken = useMultipleContractSingleData(lpAddresses, ERC20_ABI, 'totalSupply')

  // tokens per second, constants
  const rewardRates = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_MULTI_REWARDS_INTERFACE,
    'getRewardRates',
    undefined,
    NEVER_RELOAD
  )
  const periodFinishes = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_REWARDS_INTERFACE,
    'periodFinish',
    undefined,
    NEVER_RELOAD
  )

  const rewardPrices = useUSDCPrices(allRewardTokens)
  const rewardPricesMap = useMemo(() => new Map(rewardPrices?.map((price, i) => [allRewardTokens[i].address, price])), [
    rewardPrices,
    allRewardTokens
  ])

  const mergedTokens = useMemo(() => info.map(({ tokens }) => [tokens[0], tokens[1]]), [info])
  const flattenedTokens = ([] as Token[]).concat(...mergedTokens)
  const tokenPrices = useUSDCPrices(flattenedTokens)

  return useMemo(() => {
    if (!chainId) return []

    return rewardsAddresses.reduce<StakingInfo[]>((memo, rewardsAddress, index) => {
      // these two are dependent on account
      const balanceState = balances[index]
      const earnedAmountState = earnedAmounts[index]

      // these get fetched regardless of account
      const totalSupplyState = totalSupplies[index]
      const rewardRatesState = rewardRates[index]
      const periodFinishState = periodFinishes[index]
      const totalSupplyOfStakingTokenState = totalSuppliesOfStakingToken[index]

      if (
        // these may be undefined if not logged in
        !balanceState?.loading &&
        !earnedAmountState?.loading &&
        // always need these
        totalSupplyState &&
        !totalSupplyState.loading &&
        rewardRatesState &&
        !rewardRatesState.loading &&
        periodFinishState &&
        !periodFinishState.loading
      ) {
        if (
          balanceState?.error ||
          earnedAmountState?.error ||
          totalSupplyState.error ||
          rewardRatesState.error ||
          periodFinishState.error
        ) {
          console.error('Failed to load staking rewards info')
          return memo
        }

        // get the LP token
        const tokens = info[index].tokens
        const dummyPair = new Pair(new TokenAmount(tokens[0], '0'), new TokenAmount(tokens[1], '0'))

        // check for account, if no account set to 0
        const stakedAmount = new TokenAmount(dummyPair.liquidityToken, JSBI.BigInt(balanceState?.result?.[0] ?? 0))
        const totalStakedAmount = new TokenAmount(dummyPair.liquidityToken, JSBI.BigInt(totalSupplyState.result?.[0]))
        const totalRewardRates = info[index].rewards.map(
          (rewardToken, rewardIndex) =>
            new TokenAmount(
              rewardToken,
              JSBI.BigInt(rewardRatesState.result ? rewardRatesState.result[0][rewardIndex] : 0)
            )
        )

        const getHypotheticalRewardRates = (
          stakedAmount: TokenAmount,
          totalStakedAmount: TokenAmount,
          totalRewardRates: TokenAmount[]
        ): TokenAmount[] => {
          return totalRewardRates.map(
            (totalRewardRate, totalIndex) =>
              new TokenAmount(
                info[index].rewards[totalIndex],
                JSBI.greaterThan(totalStakedAmount.raw, JSBI.BigInt(0))
                  ? JSBI.divide(JSBI.multiply(totalRewardRate.raw, stakedAmount.raw), totalStakedAmount.raw)
                  : JSBI.BigInt(0)
              )
          )
        }

        const individualRewardRates = getHypotheticalRewardRates(
          stakedAmount,
          totalStakedAmount,
          totalRewardRates ?? []
        )

        const periodFinishSeconds = periodFinishState.result?.[0]?.toNumber()
        const periodFinishMs = periodFinishSeconds * 1000

        // compare period end timestamp vs current block timestamp (in seconds)
        const active =
          periodFinishSeconds && currentBlockTimestamp ? periodFinishSeconds > currentBlockTimestamp.toNumber() : true

        const token0 = tokens[0]
        const token1 = tokens[1]

        const [, stakingTokenPair] = pairs[index]

        let apy: Fraction | undefined = undefined

        if (totalSupplyOfStakingTokenState && totalSupplyOfStakingTokenState.result && stakingTokenPair) {
          const totalSupplyOfStakingToken = new TokenAmount(
            stakedAmount.token,
            totalSupplyOfStakingTokenState.result[0]
          )
          const totalSupplyOfStakingTokenInToken0 = new TokenAmount(
            token0,
            JSBI.divide(
              JSBI.multiply(
                JSBI.multiply(totalStakedAmount.raw, stakingTokenPair.reserveOf(token0).raw),
                JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
              ),
              totalSupplyOfStakingToken.raw
            )
          )

          const totalSupplyOfStakingTokenInToken1 = new TokenAmount(
            token1,
            JSBI.divide(
              JSBI.multiply(
                JSBI.multiply(totalStakedAmount.raw, stakingTokenPair.reserveOf(token1).raw),
                JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
              ),
              totalSupplyOfStakingToken.raw
            )
          )

          const token0USD = tokenPrices?.[index * 2]
          const token1USD = tokenPrices?.[index * 2 + 1]

          const valueOfTotalStakedAmountInUSDC =
            totalSupplyOfStakingTokenInToken0 &&
            totalSupplyOfStakingTokenInToken1 &&
            (token0USD?.quote(totalSupplyOfStakingTokenInToken0) || token1USD?.quote(totalSupplyOfStakingTokenInToken1))

          const year = JSBI.BigInt(60 * 60 * 24 * 365)

          const valueOfTotalRewardsInUSDC = info[index].rewards.reduce((acc, rewardToken, rewardIndex) => {
            const rewardPerYear = new TokenAmount(
              rewardToken,
              JSBI.multiply(totalRewardRates ? totalRewardRates[rewardIndex].raw : JSBI.BigInt(0), year)
            )
            const price = rewardPricesMap.get(rewardToken.address)
            if (!price) {
              console.log('asset', rewardToken)
            }
            return price ? acc.add(price.quote(rewardPerYear)) : acc
          }, new TokenAmount(MATIC_USDC, JSBI.BigInt(0)) as CurrencyAmount)

          if (
            valueOfTotalStakedAmountInUSDC &&
            valueOfTotalStakedAmountInUSDC.greaterThan(JSBI.BigInt(0)) &&
            valueOfTotalRewardsInUSDC
          ) {
            apy = valueOfTotalRewardsInUSDC.divide(valueOfTotalStakedAmountInUSDC)
          }
        }

        const earnedAmounts = info[index].rewards.map(
          (rewardToken, rewardIndex) =>
            new TokenAmount(rewardToken, JSBI.BigInt(earnedAmountState?.result?.[0][rewardIndex] ?? 0))
        )

        memo.push({
          stakingRewardAddress: rewardsAddress,
          tokens: info[index].tokens,
          periodFinish: periodFinishMs > 0 ? new Date(periodFinishMs) : undefined,
          earnedAmounts,
          rewardRates: individualRewardRates,
          totalRewardRates,
          stakedAmount: stakedAmount,
          totalStakedAmount: totalStakedAmount,
          rewardTokens: info[index].rewards,
          getHypotheticalRewardRates,
          active,
          archived: archived,
          apy
        })
      }
      const getAPY = (x: StakingInfo) => {
        if (!x.active) {
          return -1
        }
        const v = x && x.apy ? x.apy : new Fraction(JSBI.BigInt(0))
        return parseFloat(v.toFixed(5))
      }
      const compareAPY = (a: StakingInfo, b: StakingInfo) => {
        return getAPY(b) - getAPY(a)
      }
      return memo.sort(compareAPY)
    }, [])
  }, [
    balances,
    chainId,
    currentBlockTimestamp,
    earnedAmounts,
    info,
    periodFinishes,
    rewardRates,
    rewardsAddresses,
    totalSupplies,
    totalSuppliesOfStakingToken,
    rewardPricesMap,
    tokenPrices,
    archived,
    pairs
  ])
}

export function useStakingInfo(pairToFilterBy?: Pair | null, archived: boolean = false): StakingInfo[] {
  const singleRewards = useStakingSingleInfo(pairToFilterBy, archived)
  const multiRewards = useStakingMultiInfo(pairToFilterBy, archived)

  return useMemo(() => {
    return singleRewards.concat(multiRewards).sort(compareAPY)
  }, [singleRewards, multiRewards])
}

export function useTotalUniEarned(): TokenAmount | undefined {
  const { chainId } = useActiveWeb3React()
  const uni = chainId ? UNI[chainId] : undefined

  return useMemo(() => {
    if (!uni) return undefined
    return new TokenAmount(uni, '0')
  }, [uni])
}

// based on typed value
export function useDerivedStakeInfo(
  typedValue: string,
  stakingToken: Token,
  userLiquidityUnstaked: TokenAmount | undefined
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()

  const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingToken)

  const parsedAmount =
    parsedInput && userLiquidityUnstaked && JSBI.lessThanOrEqual(parsedInput.raw, userLiquidityUnstaked.raw)
      ? parsedInput
      : undefined

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!parsedAmount) {
    error = error ?? 'Enter an amount'
  }

  return {
    parsedAmount,
    error
  }
}
