import { ChainId, Token } from '@uniswap/sdk'

import {
  MUST,
  MATIC_WETH,
  WMATIC,
  MAUSDC,
  AAVE,
  GHST,
  SDT,
  JULIEN,
  SUSHI,
  MATIC_USDC,
  WBTC,
  NDR,
  AZUKI,
  PICKLE,
  XSDT,
  DOKI,
  STR,
  AMDAI,
  AMWMATIC,
  RAZOR,
  DAI,
  USDT,
  ELET,
  TRDL,
  VBTC,
  LADZ,
  pBTC,
  BND,
  KIF,
  MWBTC,
  MWETH,
  MM,
  BIFI,
  ANY,
  BNB,
  PAUTO,
  ELE,
  BPT,
  SX,
  SHO,
  mBONK,
  gSat,
  VISION,
  AGAc,
  AGAcr,
  TITAN,
  APW,
  wPTG,
  YAM,
  MORK,
  PSP,
  LUCHA,
  miMATIC,
  QI,
  MEED,
  CMT,
} from './index'

// TODO add staking rewards addresses here
export const STAKING_REWARDS_INFO: {
  [chainId in ChainId]?: {
    tokens: [Token, Token]
    stakingRewardAddress: string
    archived?: boolean
  }[]
} = {
  [ChainId.MATIC]: [
    {
      tokens: [MATIC_WETH, MATIC_USDC],
      stakingRewardAddress: '0x1c30Cfe08506BA215c02bc2723C6D310671BAb62',
      archived: true
    },
    {
      tokens: [MATIC_WETH, MUST],
      stakingRewardAddress: '0x2cc6a7A06B32E0796D8f9225E2e33ae51C93d715',
      archived: true
    },
    {
      tokens: [WMATIC, MUST],
      stakingRewardAddress: '0x2328c83431a29613b1780706E0Af3679E3D04afd',
      archived: true
    },
    {
      tokens: [MAUSDC, MATIC_USDC],
      stakingRewardAddress: '0x9512080848be125Bd900cBBa3B0454742D11FA93',
      archived: true
    },
    {
      tokens: [JULIEN, MUST],
      stakingRewardAddress: '0x83bb796fBc69E013726129f768069e456CaDeA2b',
      archived: true
    },
    {
      tokens: [SDT, MUST],
      stakingRewardAddress: '0x8e0dc90E432c3F234d24aa2CB47dD31cF2Ad8429',
      archived: true
    },
    {
      tokens: [MAUSDC, MUST],
      stakingRewardAddress: '0x31a8fCe4d069191900E91B4Ba4bF3F3E7d1c7338',
      archived: true
    },
    {
      tokens: [GHST, MUST],
      stakingRewardAddress: '0x03A2C998A05B544B236fd51A44EFe6505eb33a9a',
      archived: true
    },
    {
      tokens: [AAVE, MUST],
      stakingRewardAddress: '0x642A056F790af3eDa4F5639aDc54083285186D90',
      archived: true
    },

    {
      tokens: [SUSHI, MUST],
      stakingRewardAddress: '0xFAd897402C0A70440e79A9Da6aAfb8a19D94D5eA',
      archived: true
    },
    {
      tokens: [NDR, MUST],
      stakingRewardAddress: '0x26FFeB3452a8D5B507cB6dcc4EcA05d33D926AF7',
      archived: true
    },
    {
      tokens: [PICKLE, MUST],
      stakingRewardAddress: '0x52f68A09AEe9503367bc0cda0748C4D81807Ae9a',
      archived: true
    },
    {
      tokens: [MATIC_WETH, WBTC],
      stakingRewardAddress: '0xd6161AdBf80602f0ecf74F92ce0271556ddbf98B',
      archived: true
    },
    {
      tokens: [XSDT, MUST],
      stakingRewardAddress: '0x67659dF49823f026E98afB2e9703C4D616EDB168',
      archived: true
    },
    {
      tokens: [XSDT, SDT],
      stakingRewardAddress: '0xfb23843D3561a3bC73BB14855286Bd4b0b3E15C6',
      archived: true
    },
    {
      tokens: [MATIC_USDC, MUST],
      stakingRewardAddress: '0x1C678EA856B368CC361A3389734fe451fEC8CEea',
      archived: true
    },
    {
      tokens: [WBTC, MUST],
      stakingRewardAddress: '0x449A45A2Db94Fb4cD14f7Af4FD2322649492225A',
      archived: true
    },

    {
      tokens: [AMWMATIC, MUST],
      stakingRewardAddress: '0xc363539ce034E61Ec6D4d913313AabC628E9A848',
      archived: true
    },

    {
      tokens: [AMDAI, MUST],
      stakingRewardAddress: '0xd0C5cCB10AA58DaADb1E49773DEF3Be399d6210D',
      archived: true
    },
    {
      tokens: [DAI, USDT],
      stakingRewardAddress: '0x9F8e609E5C1a73938739F63E2eB231a185df31b3',
      archived: true
    },
    {
      tokens: [DAI, MATIC_USDC],
      stakingRewardAddress: '0x9C9e24C8fe20ec9E3518524818e06504370cd400',
      archived: true
    },
    {
      tokens: [USDT, MATIC_USDC],
      archived: true,
      stakingRewardAddress: '0x296A8b9F19d8b6282Bbc474847D8a005B4cA09e1'
    },
    {
      tokens: [LADZ, MATIC_WETH],
      stakingRewardAddress: '0x88030CC48CaC2a7Fa3e88De3ad7826AC29903114',
      archived: true
    },
    {
      tokens: [pBTC, MUST],
      stakingRewardAddress: '0x5dE870843eA74F3f6A9A5253323788C2Ab94D9C5',
      archived: true
    },
    {
      tokens: [BND, MATIC_WETH],
      stakingRewardAddress: '0x87c23d32469FA9ddef7797Bf1d4018cf61C8b3e8',
      archived: true
    },
    {
      tokens: [BND, MUST],
      stakingRewardAddress: '0xdA39652B724b149324D47A2bAe3aD3F323eD4685',
      archived: true
    },
    {
      tokens: [KIF, MUST],
      archived: true,
      stakingRewardAddress: '0x868Fc3c5e007a49308bf86168E3fd9D5DBC8ca30'
    },
    {
      tokens: [WMATIC, MATIC_WETH],
      stakingRewardAddress: '0x5A25c4f43d0bfcCc07Aa86f7e8a1a1A3bFd9b15d',
      archived: true
    },
    {
      tokens: [AMWMATIC, WMATIC],
      stakingRewardAddress: '0x9477D5496ee5f758b26FFAb540a0F5cc4C48D157',
      archived: true
    },
    {
      tokens: [ANY, WMATIC],
      archived: true,
      stakingRewardAddress: '0x142C667cC3B5f40A982aee028c9010ACCe7A79db'
    },
    {
      tokens: [ANY, MUST],
      archived: true,
      stakingRewardAddress: '0x656A8DFc2b6766F18425B23D9F05362cf15C7954'
    },
    {
      tokens: [PAUTO, MATIC_WETH],
      archived: true,
      stakingRewardAddress: '0x1253C2326eBc1494390DdbF32C36ae077B37049B'
    },
    {
      tokens: [BNB, WMATIC],
      archived: true,
      stakingRewardAddress: '0xEe217DbaF6c83a512ed616096aCAd498da389a29'
    },
    {
      tokens: [PAUTO, WMATIC],
      archived: true,
      stakingRewardAddress: '0xDA848288713FFdE62790aEFA4F945141f7938fFE'
    },
    {
      tokens: [PAUTO, MUST],
      archived: true,
      stakingRewardAddress: '0xA80E8cc955D8e803cC1EC1578657688aFD5BdEF8'
    },
    {
      tokens: [ELE, WMATIC],
      archived: true,
      stakingRewardAddress: '0x0851A1C158149D283fcb45dA1E8d3221aAC17BBd'
    },

    {
      tokens: [ELE, MUST],
      archived: true,
      stakingRewardAddress: '0x4E25125483507Fd3D1Fb1Cd5ea38c6f11f146cD1'
    },
    {
      tokens: [VISION, MATIC_WETH],
      archived: true,
      stakingRewardAddress: '0x469660e6c6479a9fabc098958abf90dd35982f2a'
    },
    {
      tokens: [VISION, MUST],
      archived: true,
      stakingRewardAddress: '0xde2a18faba435418dc008add62e598498a7b7fc1'
    },
    {
      tokens: [AGAc, MUST],
      archived: true,
      stakingRewardAddress: '0xE6d554655bB1bACd18f4b03e883797d0237c9b17'
    },
    {
      tokens: [AGAcr, MUST],
      archived: true,
      stakingRewardAddress: '0x6a7f36DD48789672FA7AE682869307bf3927104a'
    },
    {
      tokens: [AGAcr, AGAc],
      archived: true,
      stakingRewardAddress: '0xd0036aD6233a9Fd2b5E6872DDdc1ccC9cb70F6c2'
    },
    {
      tokens: [APW, MATIC_WETH],
      archived: true,
      stakingRewardAddress: '0xef9a00F4193A09Fd4eb5173DFDc750Aca5b95815'
    },
    {
      tokens: [APW, MUST],
      archived: true,
      stakingRewardAddress: '0xB7Ae78f49aC9bD9388109a4c5f53C6b79BE4dEdA'
    },
    {
      tokens: [MATIC_USDC, TITAN],
      archived: true,
      stakingRewardAddress: '0x101eCe12a97D413C7406f4F94929bD9F6dF777eA'
    },
    {
      tokens: [MUST, TITAN],
      archived: true,
      stakingRewardAddress: '0x476438a51E54BE1E6846B484beAd1842E7E885e2'
    }
  ]
}

export const STAKING_MULTI_REWARDS_INFO: {
  [chainId in ChainId]?: {
    tokens: [Token, Token]
    rewards: Token[]
    stakingMultiRewardsAddress: string
    archived?: boolean
  }[]
} = {
  [ChainId.MATIC]: [
    {
      tokens: [MATIC_WETH, MUST],
      stakingMultiRewardsAddress: '0x71E2C0d0e0163EcbD7C51d9bbaeF3352377b7950',
      rewards: [MUST]
    },
    {
      tokens: [WMATIC, MUST],
      stakingMultiRewardsAddress: '0x2Ae5C226A034ee30e3E63c17fA664F8c1da2b615',
      rewards: [MUST]
    },
    {
      tokens: [MATIC_WETH, MATIC_USDC],
      stakingMultiRewardsAddress: '0x6fB4319F1A403C4e5c8dD6a241Fcf580070b5ae1',
      rewards: [MUST]
    },
    {
      tokens: [PICKLE, MUST],
      stakingMultiRewardsAddress: '0xd1583C05F208cc41a0CCde1A55f6922443509683',
      rewards: [MUST]
    },
    {
      tokens: [MATIC_USDC, MUST],
      stakingMultiRewardsAddress: '0xEd5ab07cEA5D1BC8744fFA165b3E5a173657CD65',
      rewards: [MUST]
    },
    {
      tokens: [MATIC_WETH, WBTC],
      stakingMultiRewardsAddress: '0xC72807DC7eb468204316b3b624715E280C1493B9',
      rewards: [MUST]
    },
    {
      tokens: [WMATIC, MATIC_WETH],
      stakingMultiRewardsAddress: '0xBa8CEF901E1eC5CFFCCAd3F4202a1CF4a00b6759',
      rewards: [MUST]
    },

    {
      tokens: [LADZ, MATIC_WETH],
      stakingMultiRewardsAddress: '0x7D391F05d8cf17eBCeDc5Ee4982153e274800490',
      rewards: [MUST, LADZ]
    },
    {
      tokens: [LADZ, MUST],
      stakingMultiRewardsAddress: '0xE9851594bB6661713ac8712f0078C2B68529a9dF',
      rewards: [MUST, LADZ]
    },
    {
      tokens: [MATIC_WETH, SDT],
      stakingMultiRewardsAddress: '0x8417AE2b055ED3D2eF2eFC50Ef1B5b64c36C9731',
      rewards: [MUST, SDT]
    },
    {
      tokens: [MM, MUST],
      stakingMultiRewardsAddress: '0xFa9217D24E64A7e1E992184D1eb92946b12FdACf',
      rewards: [MUST, MM]
    },
    {
      tokens: [MWETH, MUST],
      stakingMultiRewardsAddress: '0xE9fD7DC76b45d3ea345BBCb1a38495227e14f808',
      rewards: [MUST, MM]
    },
    {
      tokens: [MWBTC, MUST],
      stakingMultiRewardsAddress: '0xa324BFaB3fE20179240d7C4bfB692B45326e5683',
      rewards: [MUST, MM]
    },
    {
      tokens: [BPT, MATIC_WETH],
      stakingMultiRewardsAddress: '0x29a10388C1aDf4df4b095f959E831d5c40C03A0d',
      rewards: [MUST, BPT]
    },
    {
      tokens: [BPT, MUST],
      stakingMultiRewardsAddress: '0x8998244c222dd47126E54509b332d6604B0d4A74',
      rewards: [MUST, BPT]
    },
    {
      tokens: [APW, MATIC_WETH],
      stakingMultiRewardsAddress: '0x277A2C1F890D527434cA5eA1286019e083c5A8ee',
      rewards: [MUST, APW]
    },
    {
      tokens: [WMATIC, wPTG],
      stakingMultiRewardsAddress: '0x804eE68520039375C7c5363c5719ecE23d85fA64',
      rewards: [MUST, wPTG]
    },
    {
      tokens: [MATIC_WETH, MORK],
      stakingMultiRewardsAddress: '0x142876C3d0fbEde421573F69baEc79fB3693919D',
      rewards: [MUST, MORK]
    },
    {
      tokens: [LUCHA, WMATIC],
      stakingMultiRewardsAddress: '0x0d008974359e5aD1B64c4edc4de3C46ED662b6D8',
      rewards: [MUST, LUCHA, QI, PSP, miMATIC]
    },
    {
      tokens: [LUCHA, MUST],
      stakingMultiRewardsAddress: '0xe11f861dD5cE8407bb24dFD13b9710c0295276D6',
      rewards: [MUST, LUCHA, QI, PSP, miMATIC]
    },
    {
      tokens: [PSP, WMATIC],
      rewards: [MUST, PSP],
      stakingMultiRewardsAddress: '0x13E4473aE9A8bE50d7aB6F5F5574165e98d58a34'
    },
    {
      tokens: [PSP, MUST],
      rewards: [MUST, PSP],
      stakingMultiRewardsAddress: '0x575D75d92D9e6FdAd930A51c4F40bb632a647927'
    },
    {
      tokens: [MEED, WMATIC],
      rewards: [MUST, MEED],
      stakingMultiRewardsAddress: '0x035A8a07Bbae988893499e5c0D5b281b7967b107'
    },
    {
      tokens: [DOKI, MATIC_WETH],
      rewards: [MUST, DOKI],
      stakingMultiRewardsAddress: '0x81AbDd138c50D577cda3116b763d2e3f82CE8eA2',
      archived: true
    },
    {
      tokens: [DOKI, MUST],
      rewards: [MUST, DOKI],
      stakingMultiRewardsAddress: '0xD6644eA185Ed9CE3Df1D91B54471D27B36a65c30',
      archived: true
    },

    {
      tokens: [DOKI, MATIC_WETH],
      rewards: [MUST, AZUKI],
      archived: true,
      stakingMultiRewardsAddress: '0xBbDC1681e43549d3871CF1953D1dD9afF320feF0'
    },
    {
      tokens: [DOKI, MUST],
      rewards: [MUST, AZUKI],
      archived: true,
      stakingMultiRewardsAddress: '0x2146baC214D9BF2Da56c3d4A69b9149e457F9d8c'
    },
    {
      tokens: [AZUKI, MUST],
      rewards: [MUST, AZUKI],
      archived: true,
      stakingMultiRewardsAddress: '0xc0a1dFb85734E465C5dadc5683DE58358C906598'
    },
    {
      tokens: [AZUKI, MATIC_WETH],
      rewards: [MUST, AZUKI],
      archived: true,
      stakingMultiRewardsAddress: '0x69Cb6f98E45c13A230d292bE0a6aF93a6521c39B'
    },
    {
      tokens: [STR, MUST],
      rewards: [MUST, STR],
      archived: true,
      stakingMultiRewardsAddress: '0x93ca5835c6A36Cf58C653cD6dc20EC381DCb89Bd'
    },
    {
      tokens: [ELET, MUST],
      rewards: [MUST, ELET],
      archived: true,
      stakingMultiRewardsAddress: '0xFdA044F14B6590c211a50854F36C2eB5f9a9c0a0'
    },
    {
      tokens: [MATIC_WETH, VBTC],
      rewards: [MUST, gSat],
      archived: true,
      stakingMultiRewardsAddress: '0xc7B2B0E8Fda3EBf16Bc6020B69Bf2b6e51ce8662'
    },

    {
      tokens: [MATIC_WETH, VBTC],
      rewards: [MUST, gSat, TRDL],
      archived: true,
      stakingMultiRewardsAddress: '0x33a3EC6bf58e420C691d41fBB034FDd74551B310'
    },
    {
      tokens: [WBTC, VBTC],
      rewards: [MUST, TRDL],
      archived: true,
      stakingMultiRewardsAddress: '0xd65bfB8b20B08724F2772f95296dCd4ebEBACfcb'
    },
    {
      tokens: [ELET, WMATIC],
      rewards: [MUST, ELET],
      archived: true,
      stakingMultiRewardsAddress: '0x1cFc3E212C7B9207C8388ed08B88570d1809E15a'
    },

    // TRDL MUST
    {
      tokens: [TRDL, MUST],
      rewards: [MUST, TRDL],
      archived: true,
      stakingMultiRewardsAddress: '0x8Cf8C7Db0Ab27985973a0E01A8a5CE2CFfd468ed'
    },
    {
      tokens: [TRDL, MUST],
      rewards: [MUST, gSat],
      archived: true,
      stakingMultiRewardsAddress: '0xf7880804362C7bEBE7cEd6d1FeE7146EbF05EC4a'
    },

    {
      tokens: [TRDL, MUST],
      rewards: [MUST, gSat, TRDL],
      archived: true,
      stakingMultiRewardsAddress: '0x563810eC53e8199f984CE0B50c7fD1827098481a'
    },

    {
      tokens: [MATIC_WETH, SDT],
      rewards: [MUST, SDT],
      archived: true,
      stakingMultiRewardsAddress: '0x7C991dF9CDaFB1F62BefD5D6502fF81a7D85227b'
    },
    {
      tokens: [MM, MUST],
      rewards: [MUST, MM],
      archived: true,
      stakingMultiRewardsAddress: '0x2F434bC10D44Db5161934FAaa58A462C8F1cBE52'
    },
    {
      tokens: [MWETH, MUST],
      rewards: [MUST, MM],
      archived: true,
      stakingMultiRewardsAddress: '0xa964B2ef4C0326e5c0701b513d43d7f7f254E4C1'
    },
    {
      tokens: [MWBTC, MUST],
      rewards: [MUST, MM],
      archived: true,
      stakingMultiRewardsAddress: '0x066E267ff61F23d733538695da066ADA72a6fac3'
    },
    {
      tokens: [RAZOR, MATIC_WETH],
      rewards: [MUST, RAZOR],
      archived: true,
      stakingMultiRewardsAddress: '0x72c287b1c0252d2cfBA6a58f672CbC73979d9FA1'
    },
    {
      tokens: [BIFI, MUST],
      rewards: [MUST, BIFI],
      archived: true,
      stakingMultiRewardsAddress: '0x3a68F45a3Bc648365a80AbafA2528cd3277eEB96'
    },
    {
      tokens: [LADZ, MATIC_WETH],
      rewards: [MUST, LADZ],
      archived: true,
      stakingMultiRewardsAddress: '0xAB4292c600ddF1845D41c00b374DbBA5062e0810'
    },
    {
      tokens: [LADZ, MUST],
      rewards: [MUST, LADZ],
      archived: true,
      stakingMultiRewardsAddress: '0xcA9c316760AAAF865163b0DC5066cdb89A9562Ac'
    },
    {
      tokens: [BIFI, MATIC_WETH],
      rewards: [MUST, BIFI],
      archived: true,
      stakingMultiRewardsAddress: '0x0d0Bc4C6fF3aB091d7AFd6638943690602BC7743'
    },
    {
      tokens: [BPT, MATIC_WETH],
      rewards: [MUST, BPT],
      archived: true,
      stakingMultiRewardsAddress: '0xe3AE080d6a4F1aC5AbABf514F871428342135877'
    },
    {
      tokens: [BPT, MUST],
      rewards: [MUST, BPT],
      archived: true,
      stakingMultiRewardsAddress: '0xe29544a8145978a2355E44fBAC61F4748f0EcCA6'
    },
    {
      tokens: [SHO, MUST],
      rewards: [MUST, SHO],
      archived: true,
      stakingMultiRewardsAddress: '0x295CE79a03840c14f633266240777035BC39C776'
    },
    {
      tokens: [SX, MUST],
      rewards: [MUST, SX],
      archived: true,
      stakingMultiRewardsAddress: '0xdd90B544Db1d1d1c59c6d3608de5d7C694eEbE23'
    },

    {
      tokens: [WMATIC, gSat],
      rewards: [MUST, gSat, TRDL],
      archived: true,
      stakingMultiRewardsAddress: '0xa5BA914Eee12139Bbe65fB3FbA3aA67847959712'
    },

    {
      tokens: [MATIC_WETH, mBONK],
      rewards: [MUST, mBONK],
      archived: true,
      stakingMultiRewardsAddress: '0xca04AA9d570845B6a47d8F0f375381e2a9a82B89'
    },

    {
      tokens: [WMATIC, wPTG],
      rewards: [MUST, wPTG],
      archived: true,
      stakingMultiRewardsAddress: '0xAE4985c56647970870beB871864184ceCf5A60Bb'
    },

    {
      tokens: [YAM, MUST],
      rewards: [MUST, YAM],
      archived: true,
      stakingMultiRewardsAddress: '0x7f5E382c2a76Af8Eb97625206712c747A6a18F1D'
    },
    {
      tokens: [MATIC_WETH, MORK],
      rewards: [MUST, MORK],
      archived: true,
      stakingMultiRewardsAddress: '0xEA98BC930D0f6c71aFE7D717A940170cCbd6a8CE'
    },
    {
      tokens: [MATIC_WETH, APW],
      rewards: [MUST, APW],
      archived: true,
      stakingMultiRewardsAddress: '0x4e2114F7FA11Dc0765ddD51Ad98B6624C3bf1908'
    },
    {
      tokens: [LUCHA, WMATIC],
      rewards: [MUST, LUCHA, QI, PSP, miMATIC],
      archived: true,
      stakingMultiRewardsAddress: '0x6Fa736A297CD498521e2D0d0EA512E57DbDA5618'
    },
    {
      tokens: [LUCHA, MUST],
      rewards: [MUST, LUCHA, QI, PSP, miMATIC],
      archived: true,
      stakingMultiRewardsAddress: '0x1ba117bFdC9C0c2014004ddB621862997c074Cc7'
    },
    {
      tokens: [LUCHA, WMATIC],
      rewards: [MUST, LUCHA],
      archived: true,
      stakingMultiRewardsAddress: '0x9Dc2b914172Beb78dBD077800f088AdDa4051c8c'
    },
    {
      tokens: [LUCHA, MUST],
      rewards: [MUST, LUCHA],
      archived: true,
      stakingMultiRewardsAddress: '0xC2A20288904b4886d82FA08F4277e662fD24D82d'
    },
    {
      tokens: [PSP, WMATIC],
      rewards: [MUST, PSP],
      archived: true,
      stakingMultiRewardsAddress: '0x1D85b838667471a1D1FFB0b91AeD019840e9bDd6'
    },
    {
      tokens: [PSP, MUST],
      rewards: [MUST, PSP],
      archived: true,
      stakingMultiRewardsAddress: '0x3408C63BD09927651e9755B0E86d263e8a7B64A7'
    },
    {
      tokens: [CMT, MATIC_WETH],
      rewards: [MUST, CMT],
      stakingMultiRewardsAddress: '0x718bB64D85dA64EB20670A2AFBd7F61F9803b7f7'
    }
  ]
}
