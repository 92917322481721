import { ChainId, JSBI, Percent, Token, WETH } from '@uniswap/sdk'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { WrappedTokenInfo } from '../state/lists/hooks'

import { injected, walletconnect, stakedao, cometh, torus } from '../connectors'

export const ROUTER_ADDRESS = '0x93bcDc45f7e62f89a8e901DC4A0E2c6C427D9F25'

/*
export const ROUTER_ADDRESS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
  [ChainId.ROPSTEN]: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
  [ChainId.RINKEBY]: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
  [ChainId.GÖRLI]: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
  [ChainId.KOVAN]: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
  [ChainId.MUMBAI]: '0xAA5B2Bf9584d4eFF453E9C9A521a88C789F5F645',
  [ChainId.MATIC]: '0x25c6d9f3480f55e7015EF2DB14e7B05bDE64b435'
}
*/

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export { PRELOADED_PROPOSALS } from './proposals'

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

export const MUST = new WrappedTokenInfo(
  {
    name: 'MUST',
    address: '0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f',
    symbol: 'MUST',
    chainId: ChainId.MATIC,
    decimals: 18,
    logoURI: 'https://etherscan.io/token/images/cometh_32.png'
  },
  []
)
export const WMATIC = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    decimals: 18,
    symbol: 'WMATIC',
    name: 'Wrapped Matic',
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0/logo.png'
  },
  []
)
export const WBTC = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
    decimals: 8,
    symbol: 'WBTC',
    name: 'Wrapped BTC',
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png'
  },
  []
)
export const GHST = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x385Eeac5cB85A38A9a07A70c73e0a3271CfB54A7',
    decimals: 18,
    symbol: 'GHST',
    name: 'Aavegotchi GHST Token',
    logoURI: 'https://aavegotchi.com/images/ghsttoken.svg'
  },
  []
)
export const AAVE = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0xD6DF932A45C0f255f85145f286eA0b292B21C90B',
    decimals: 18,
    symbol: 'AAVE',
    name: 'Aave',
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9/logo.png'
  },
  []
)
export const MAUSDC = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x9719d867A500Ef117cC201206B8ab51e794d3F82',
    decimals: 6,
    symbol: 'maUSDC',
    name: 'Matic Aave interest bearing USDC',
    logoURI: 'https://aavegotchi.com/images/matokens/maUSDC.svg'
  },
  []
)
export const SDT = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x361A5a4993493cE00f61C32d4EcCA5512b82CE90',
    decimals: 18,
    symbol: 'SDT',
    name: 'Stake DAO Token',
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/sdt.png'
  },
  []
)
export const SDEURSCRV = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0xfBdb45075Fb73ca4CC8b83FD6fdB4F9B696B1BA1',
    decimals: 18,
    symbol: 'sdeursCRV',
    name: 'stake dao Curve.fi EURS/sEUR',
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/sdt-eur.png'
  },
  []
)
export const SDCRVRENWSBTC = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0xe212f92E5Af85268B33D2Aa587B51f49C3B945Be',
    decimals: 18,
    symbol: 'sdcrvRenWSBTC',
    name: 'stake dao Curve.fi renBTC/wBTC/sBTC',
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/sdt-btc.png'
  },
  []
)
export const SD3CRV = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x87f0BFEE4435CE2B8643b221D0C1CAd21F5328B4',
    decimals: 18,
    symbol: 'sd3Crv',
    name: 'stake dao Curve.fi DAI/USDC/USDT',
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/sdt-usd.png'
  },
  []
)
export const JULIEN = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x268Ad27C28601D28b79c792C14e95BD2b7A030F8',
    decimals: 4,
    symbol: 'JULIEN',
    name: 'Julien',
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xe6710e0CdA178f3D921f456902707B0d4C4A332B/logo.png'
  },
  []
)
export const SUSHI = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a',
    decimals: 18,
    symbol: 'SUSHI',
    name: 'SushiToken',
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B3595068778DD592e39A122f4f5a5cF09C90fE2/logo.png'
  },
  []
)
export const MATIC_USDC = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    decimals: 6,
    symbol: 'USDC',
    name: 'USD Coin',
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png'
  },
  []
)
export const USDC = new WrappedTokenInfo(
  {
    chainId: ChainId.MAINNET,
    address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    decimals: 6,
    symbol: 'USDC',
    name: 'USD//C',
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png'
  },
  []
)

export const NDR = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0xfb65ef42F7c8A70ff73F627DB6E9ba2Aff1F20fa',
    decimals: 18,
    symbol: 'NDR',
    name: 'NodeRunners',
    logoURI: 'https://tokens.1inch.exchange/0x739763a258640919981f9ba610ae65492455be53.png'
  },
  []
)
export const AZUKI = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x7CdC0421469398e0F3aA8890693d86c840Ac8931',
    decimals: 18,
    symbol: 'AZUKI',
    name: 'DokiDokiAzuki',
    logoURI: 'https://tokens.1inch.exchange/0x910524678c0b1b23ffb9285a81f99c29c11cbaed.png'
  },
  []
)
export const PICKLE = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x2b88aD57897A8b496595925F43048301C37615Da',
    decimals: 18,
    symbol: 'PICKLE',
    name: 'PickleToken',
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x429881672B9AE42b8EbA0E26cD9C73711b891Ca5/logo.png'
  },
  []
)
export const BBADGER = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x2628D301b161DB70E3dBbAc88d9D900cA426fF02',
    decimals: 18,
    symbol: 'bBADGER',
    name: 'Badger Sett Badger',
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x3472A5A71965499acd81997a54BBA8D852C6E53d/logo.png'
  },
  []
)
export const BDIGG = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0xFDde616084427f0A231D0664a985E1F820E34693',
    decimals: 18,
    symbol: 'bDIGG',
    name: 'Badger Sett Digg',
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x798D1bE841a82a273720CE31c822C61a67a601C3/logo.png'
  },
  []
)
export const XSDT = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0xD921F8318cfd786baB1ea7492673F053c518Ac04',
    decimals: 18,
    symbol: 'xSDT',
    name: 'Staked SDT',
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/xsdt.png'
  },
  []
)

export const AMUSDC = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x1a13F4Ca1d028320A707D99520AbFefca3998b7F',
    decimals: 6,
    symbol: 'amUSDC',
    name: 'Aave Matic Market USDC',
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/aUSDC.png'
  },
  []
)
export const AMUSDT = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x60D55F02A771d515e077c9C2403a1ef324885CeC',
    decimals: 6,
    symbol: 'amUSDT',
    name: 'Aave Matic Market USDT',
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/aUSDT.png'
  },
  []
)
export const AMDAI = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x27F8D03b3a2196956ED754baDc28D73be8830A6e',
    decimals: 18,
    symbol: 'amDAI',
    name: 'Aave Matic Market DAI',
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/aDAI.png'
  },
  []
)
export const AMWBTC = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x5c2ed810328349100A66B82b78a1791B101C9D61',
    decimals: 8,
    symbol: 'amWBTC',
    name: 'Aave Matic Market WBTC',
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/aWBTC.png'
  },
  []
)
export const AMWETH = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x28424507fefb6f7f8E9D3860F56504E4e5f5f390',
    decimals: 18,
    symbol: 'amWETH',
    name: 'Aave Matic Market WETH',
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/aETH.png'
  },
  []
)
export const AMWMATIC = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x8dF3aad3a84da6b69A4DA8aeC3eA40d9091B2Ac4',
    decimals: 18,
    symbol: 'amWMATIC',
    name: 'Aave Matic Market WMATIC',
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/aMATIC.png'
  },
  []
)
export const AMAAVE = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x1d2a0E5EC8E5bBDCA5CB219e649B565d8e5c3360',
    decimals: 18,
    symbol: 'amAAVE',
    name: 'Aave Matic Market AAVE',
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/aAAVE.png'
  },
  []
)
export const DAI = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
    decimals: 18,
    symbol: 'DAI',
    name: 'Dai Stablecoin',
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png'
  },
  []
)

export const USDT = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    decimals: 6,
    symbol: 'USDT',
    name: 'Tether USD',
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png'
  },
  []
)

export const RAZOR = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0xC91c06DB0f7bFFba61e2A5645CC15686F0a8c828',
    decimals: 18,
    symbol: 'RAZOR',
    name: 'Razor',
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/razor.png'
  },
  []
)

export const DOKI = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x5c7f7fe4766fe8f0fa9b41e2e4194d939488ff1c',
    decimals: 18,
    symbol: 'DOKI',
    name: 'DokiDokiFinance',
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x9cEB84f92A0561fa3Cc4132aB9c0b76A59787544/logo.png'
  },
  []
)

export const STR = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0xa79e0bfc579c709819f4a0e95d4597f03093b011',
    decimals: 18,
    symbol: 'STR',
    name: 'Stater',
    logoURI: 'https://assets.coingecko.com/coins/images/14476/small/stater.png'
  },
  []
)

export const VBTC = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x1489F4F7E3a80A0aD9fA23C39D2E8af818204ce9',
    decimals: 18,
    symbol: 'vBTC',
    name: 'Strudel BTC',
    logoURI: 'https://etherscan.io/token/images/strudelbtc_32.png'
  },
  []
)
export const ELET = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x07738Eb4ce8932CA961c815Cb12C9d4ab5Bd0Da4',
    decimals: 18,
    symbol: 'ELET',
    name: 'Elementeum',
    logoURI: 'https://etherlegends.com/ELET.png'
  },
  []
)
export const TRDL = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0xf89887862538cE8eFBdbAdD5d483AE4A09dD9688',
    decimals: 18,
    symbol: 'TRDL',
    name: 'Strudel Finance',
    logoURI: 'https://assets.coingecko.com/coins/images/13854/small/1614634281474-TRDL_LOGO_PNG.png'
  },
  []
)
export const LADZ = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x220ED61d1f1CC754cB71978585D69e07Be576315',
    decimals: 4,
    symbol: 'LADZ',
    name: 'LADZ',
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/ladz.jpg'
  },
  []
)

export const pBTC = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0xd7ecf95Cf7eF5256990BeAf4ac895cD9e64cb947',
    decimals: 18,
    symbol: 'PBTC',
    name: 'pTokens BTC',
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/pbtc.svg'
  },
  []
)

export const BND = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x032b3217241fe53fFaDB334a9d06aACd2A9153D9',
    decimals: 18,
    symbol: 'BND',
    name: 'DokiDoki.Chainbinders',
    logoURI: 'https://chainbinders.degacha.com/static/media/small-bnd.8be8d932.svg'
  },
  []
)

export const KIF = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x5f084F3EE7EA09E4C01CEE3cDF1B5620A3344fd0',
    decimals: 18,
    symbol: 'KIF',
    name: 'KittenFinance',
    logoURI: 'https://media.discordapp.net/attachments/837682852040278026/838477319626686464/KIF-KittenFinance-logo.png'
  },
  []
)

export const MM = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x2E220744f9aC1BF3045B0588D339F5fD3bB8623A',
    decimals: 18,
    symbol: 'MM',
    name: 'MMToken',
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/MMtoken.png'
  },
  []
)

export const MWETH = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0xdc0f3C12dF89593357985491bdAcc5Fd789cA4B2',
    decimals: 18,
    symbol: 'mWETH',
    name: 'mushrooming Wrapped Ether',
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/mWETH.jpg'
  },
  []
)

export const MWBTC = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x652E36ecFA0E4F7e435F6D38688D68a3bE65639c',
    decimals: 8,
    symbol: 'mWBTC',
    name: 'mushrooming Wrapped BTC',
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/mWBTC.jpg'
  },
  []
)

export const BIFI = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0xFbdd194376de19a88118e84E279b977f165d01b8',
    decimals: 18,
    symbol: 'BIFI',
    name: 'Beefy',
    logoURI: 'https://raw.githubusercontent.com/beefyfinance/beefy-app/prod/src/images/single-assets/BIFI.png'
  },
  []
)
export const RBAG = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0xeffca2dbb2d9f3816cee07ae2134574fa9210dcb',
    decimals: 18,
    symbol: 'RBAG',
    name: 'RebalanceGovernance',
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/rbag.jpeg'
  },
  []
)

export const ADDY = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0xc3FdbadC7c795EF1D6Ba111e06fF8F16A20Ea539',
    decimals: 18,
    symbol: 'ADDY',
    name: 'Adamant',
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/addy.jpg'
  },
  []
)

export const ANY = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x6aB6d61428fde76768D7b45D8BFeec19c6eF91A8',
    decimals: 18,
    symbol: 'ANY',
    name: 'Anyswap',
    logoURI: 'https://raw.githubusercontent.com/anyswap/Brand-assets/master/logo/c-128-color-2%402x.png'
  },
  []
)

export const BNB = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F',
    decimals: 18,
    symbol: 'BNB',
    name: 'Binance',
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/bnb.png'
  },
  []
)

export const PAUTO = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x7f426F6Dc648e50464a0392E60E1BB465a67E9cf',
    decimals: 18,
    symbol: 'pAUTO',
    name: 'Autofarm',
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/autofarm.jpeg'
  },
  []
)

export const APW = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x6c0ab120dbd11ba701aff6748568311668f63fe0',
    decimals: 18,
    symbol: 'APW',
    name: 'APWine Token',
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/APWine.jpeg'
  },
  []
)

export const TITAN = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0xaaa5b9e6c589642f98a1cda99b9d024b8407285a',
    decimals: 18,
    symbol: 'TITAN',
    name: 'IRON Titanium Token (TITAN)',
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/titan.jpeg'
  },
  []
)

// because WETH of sdk represents the WMATIC
export const MATIC_WETH = new WrappedTokenInfo(
  {
    name: 'Wrapped Ether',
    address: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
    symbol: 'WETH',
    decimals: 18,
    chainId: ChainId.MATIC,
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png'
  },
  []
)

export const ELE = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0xacd7b3d9c10e97d0efa418903c0c7669e702e4c0',
    decimals: 18,
    symbol: 'ELE',
    name: 'Eleven.finance',
    logoURI: 'https://eleven.finance/ELE-logo.svg'
  },
  []
)

export const BPT = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x6863BD30C9e313B264657B107352bA246F8Af8e0',
    decimals: 18,
    symbol: 'BPT',
    name: 'Blackpool',
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/blackpool.jpeg'
  },
  []
)

export const SHO = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x9e01e0A928588aE6E669b8d1F0f1fa4AB976f617',
    decimals: 18,
    symbol: 'SHO',
    name: 'Showcase',
    logoURI:
      'https://raw.githubusercontent.com/cometh-game/default-token-list/ad8c53fa2bd31dfa827c7107e96f3a1591a260ed/img/Showcase_Logos.svg'
  },
  []
)

export const SX = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x840195888Db4D6A99ED9F73FcD3B225Bb3cB1A79',
    decimals: 18,
    symbol: 'SX',
    name: 'SportX',
    logoURI: 'https://raw.githubusercontent.com/sameepsi/quickswap-default-token-list/master/assets/sx.jpg'
  },
  []
)

export const mRBAL = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x66768ad00746aC4d68ded9f64886d55d5243f5Ec',
    decimals: 18,
    symbol: 'mRBAL',
    name: 'Matic Rebalance Token',
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/mRBAL.jpeg'
  },
  []
)

export const mBONK = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0xd93c61d4418d77a537b6b57478c108e193362f0c',
    decimals: 18,
    symbol: 'mBONK',
    name: 'megaBonk',
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/mBonk.png'
  },
  []
)

export const gSat = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x6a174b728d8b7c0c1f239910bb90b3540e1cc0dd',
    decimals: 18,
    symbol: 'gSat',
    name: 'GrumpySat',
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/gSAT.jpeg'
  },
  []
)

export const VISION = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x034b2090b579228482520c589dbd397c53fc51cc',
    decimals: 18,
    symbol: 'VISION',
    name: 'VISION',
    logoURI: 'https://s3-us-west-2.amazonaws.com/acf-uploads/apyvisionlogo200circle.png'
  },
  []
)

export const LAD = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x8465d41d66ce05bde12fd3320f260e01aa4ced3f',
    decimals: 18,
    symbol: 'LAD',
    name: 'Ladarken'
  },
  []
)
export const AGAc = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x669ddc70273084ea30e6cd4f28ca6e2c70735065',
    decimals: 18,
    symbol: 'AGAc',
    name: 'AGAc',
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/agac.png'
  },
  []
)
export const AGAcr = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0xcbce9f77921c2e90522d438df4c5582f4c617768',
    decimals: 18,
    symbol: 'AGAcr',
    name: 'AGAcr',
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/agacr.png'
  },
  []
)

export const wPTG = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0xc0f14c88250e680ecd70224b7fba82b7c6560d12',
    decimals: 18,
    symbol: 'wPTG',
    name: 'Wrapped Prestige',
    logoURI: 'https://arena.cryptocolosseum.com/images/icons/prestige.svg'
  },
  []
)

export const renDOGE = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0xce829a89d4a55a63418bcc43f00145adef0edb8e',
    decimals: 8,
    symbol: 'renDOGE',
    name: 'renDOGE',
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/Doge.jpeg'
  },
  []
)

export const YAM = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0xb3b681dee0435ecc0a508e40b02b3c9068d618cd',
    decimals: 18,
    symbol: 'YAM',
    name: 'YAM',
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/yam.png'
  },
  []
)

export const MORK = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0xa707734bd310883e133e0ca23f6c166aeb2a1a7a',
    decimals: 4,
    symbol: 'MORK',
    name: 'MORK',
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/Mork.png'
  },
  []
)

export const LUCHA = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x6749441Fdc8650b5b5a854ed255C82EF361f1596',
    decimals: 18,
    symbol: 'LUCHA',
    name: 'Luchadores.io LUCHA Token',
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/lucha.png'
  },
  []
)

// const miMATIC = new Token(ChainId.MATIC, '0xa3fa99a148fa48d14ed51d610c367c61876997f1', 18, 'miMATIC', 'miMATIC')
// const QI = new Token(ChainId.MATIC, '0x580a84c73811e1839f75d86d75d88cca0c241ff4', 18, 'QI', 'QI')

export const miMATIC = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0xa3fa99a148fa48d14ed51d610c367c61876997f1',
    decimals: 18,
    symbol: 'miMATIC',
    name: 'miMATIC',
    logoURI: 'https://polygonscan.com/token/images/miMatic_32.png'
  },
  []
)

export const QI = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x580a84c73811e1839f75d86d75d88cca0c241ff4',
    decimals: 18,
    symbol: 'QI',
    name: 'QI',
    logoURI: 'https://polygonscan.com/token/images/qidao_32.png'
  },
  []
)

export const PSP = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x42d61D766B85431666B39B89C43011f24451bFf6',
    decimals: 18,
    symbol: 'PSP',
    name: 'Paraswap',
    logoURI:
      'https://raw.githubusercontent.com/cometh-game/default-token-list/a6eb86854af8368529fa6798ddfdf30daf9a5431/img/ParaSwap.svg'
  },
  []
)

export const MEED = new WrappedTokenInfo(
  {
    chainId: ChainId.MATIC,
    address: '0x6acA77CF3BaB0C4E8210A09B57B07854a995289a',
    decimals: 18,
    symbol: 'MEED',
    name: 'Meeds DAO',
    logoURI: 'https://github.com/Meeds-io/.github/raw/main/profile/meeds-256x256.png'
  },
  []
)

export const Aluminium = new WrappedTokenInfo(
  {
    name: 'Aluminium',
    address: '0x041b4394F2a1A2EAF7e21460Ef59370789cb1F42',
    symbol: 'Al',
    decimals: 18,
    chainId: ChainId.MATIC,
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/Al.png'
  },
  []
)

export const Magnesium = new WrappedTokenInfo(
  {
    name: 'Magnesium',
    address: '0x2b70b0dDb96E2581a311dEB25A1424C7167E4A53',
    symbol: 'Mg',
    decimals: 18,
    chainId: ChainId.MATIC,
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/Mg.png'
  },
  []
)

export const Tungsten = new WrappedTokenInfo(
  {
    name: 'Tungsten',
    address: '0x52fc543Bd23D1948F11eb265eAbDEfBbB0131572',
    symbol: 'W',
    decimals: 18,
    chainId: ChainId.MATIC,
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/W.png'
  },
  []
)

export const Copper = new WrappedTokenInfo(
  {
    name: 'Copper',
    address: '0xf6351c1163314e95d025575c5CeA314b055b7ED3',
    symbol: 'Cu',
    decimals: 18,
    chainId: ChainId.MATIC,
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/Cu.png'
  },
  []
)

export const Titanium = new WrappedTokenInfo(
  {
    name: 'Titanium',
    address: '0xd0ecD879dC1403697D341c9Db1db51075D2adC69',
    symbol: 'Ti',
    decimals: 18,
    chainId: ChainId.MATIC,
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/Ti.png'
  },
  []
)

export const CMT = new WrappedTokenInfo(
  {
    name: 'CommitDAO',
    address: '0x9bc0abf87f9350cde55b2cda219871726d620bf9',
    symbol: 'CMT',
    decimals: 18,
    chainId: ChainId.MATIC,
    logoURI: 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/CMT.svg'
  },
  []
)

// Block time here is slightly higher (~1s) than average in order to avoid ongoing proposals past the displayed time
export const AVERAGE_BLOCK_TIME_IN_SECS = 4
export const PROPOSAL_LENGTH_IN_BLOCKS = 40_320
export const PROPOSAL_LENGTH_IN_SECS = AVERAGE_BLOCK_TIME_IN_SECS * PROPOSAL_LENGTH_IN_BLOCKS

export const GOVERNANCE_ADDRESS = '0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F'

export const TIMELOCK_ADDRESS = '0x1a9C8182C09F50C8318d769245beA52c32BE35BC'

const UNI_ADDRESS = '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984'
export const UNI: { [chainId in ChainId]: Token } = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
  [ChainId.RINKEBY]: new Token(ChainId.RINKEBY, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
  [ChainId.ROPSTEN]: new Token(ChainId.ROPSTEN, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
  [ChainId.GÖRLI]: new Token(ChainId.GÖRLI, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
  [ChainId.KOVAN]: new Token(ChainId.KOVAN, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
  [ChainId.MUMBAI]: new Token(ChainId.GÖRLI, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
  [ChainId.MATIC]: MUST
}

export const COMMON_CONTRACT_NAMES: { [address: string]: string } = {
  [MUST.address]: 'MUST',
  [UNI_ADDRESS]: 'UNI',
  [GOVERNANCE_ADDRESS]: 'Governance',
  [TIMELOCK_ADDRESS]: 'Timelock'
}

// TODO: specify merkle distributor for mainnet
export const MERKLE_DISTRIBUTOR_ADDRESS: { [chainId in ChainId]?: string } = {
  [ChainId.MAINNET]: '0x090D4613473dEE047c3f2706764f49E0821D256e'
}

const WETH_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
  [ChainId.ROPSTEN]: [WETH[ChainId.ROPSTEN]],
  [ChainId.RINKEBY]: [WETH[ChainId.RINKEBY]],
  [ChainId.GÖRLI]: [WETH[ChainId.GÖRLI]],
  [ChainId.KOVAN]: [WETH[ChainId.KOVAN]],
  [ChainId.MUMBAI]: [WETH[ChainId.MUMBAI]],
  [ChainId.MATIC]: [WETH[ChainId.MATIC]]
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MATIC]: [
    ...WETH_ONLY[ChainId.MATIC],
    MUST,
    SDT,
    WBTC,
    MATIC_USDC,
    MAUSDC,
    Aluminium,
    Magnesium,
    Tungsten,
    Copper,
    Titanium
  ]
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MATIC]: [...WETH_ONLY[ChainId.MATIC], MUST]
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MATIC]: [...WETH_ONLY[ChainId.MATIC], MUST, SDT, WBTC, MATIC_USDC, MAUSDC]
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.MATIC]: [[MUST, WETH[ChainId.MATIC]]]
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
  email?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D'
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true
  },
  TORUS: {
    connector: torus,
    name: 'Torus',
    iconName: 'torus.png',
    description: 'Connect to Torus',
    href: null,
    color: '#4196FC'
  },
  STAKEDAO: {
    connector: stakedao,
    name: 'StakeDAO',
    iconName: 'stakedao.svg',
    description: 'Connect via StakeDAO',
    href: null,
    color: '#4196FC',
    mobile: true,
    email: true
  },
  COMETH: {
    connector: cometh,
    name: 'Email',
    iconName: 'email.svg',
    description: 'Connect via Email',
    href: null,
    color: '#4196FC',
    mobile: true,
    email: true
  }
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// used for rewards deadlines
export const BIG_INT_SECONDS_IN_WEEK = JSBI.BigInt(60 * 60 * 24 * 7)

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(14)) // .0001 ETH
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), JSBI.BigInt(10000))

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

// SDN OFAC addresses
export const BLOCKED_ADDRESSES: string[] = [
  '0x7F367cC41522cE07553e823bf3be79A889DEbe1B',
  '0xd882cFc20F52f2599D84b8e8D58C7FB62cfE344b',
  '0x901bb9583b24D97e995513C6778dc6888AB6870e',
  '0xA7e5d5A720f06526557c513402f2e6B5fA20b008'
]
