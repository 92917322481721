import React from 'react'
import { AutoColumn } from '../Column'
import { RowBetween } from '../Row'
import styled from 'styled-components'
import { TYPE, StyledInternalLink } from '../../theme'
import DoubleCurrencyLogo from '../DoubleLogo'
import { JSBI, TokenAmount } from '@uniswap/sdk'
import { ButtonPrimary } from '../Button'
import { StakingInfo } from '../../state/stake/hooks'
import { useColor } from '../../hooks/useColor'
import { currencyId } from '../../utils/currencyId'
import { Break, CardNoise, CardBGImage } from './styled'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { useTotalSupply } from '../../data/TotalSupply'
import { usePair } from '../../data/Reserves'
import useUSDCPrice from '../../utils/useUSDCPrice'
import { BIG_INT_SECONDS_IN_WEEK, MUST, MATIC_WETH } from '../../constants'

const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  display: none;
`};
`

const Wrapper = styled(AutoColumn)<{ showBackground: boolean; bgColor: any }>`
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  position: relative;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '1')};
  background: ${({ theme, bgColor, showBackground }) =>
    `radial-gradient(91.85% 100% at 1.84% 0%, ${bgColor} 0%, ${showBackground ? theme.black : theme.bg5} 100%) `};
  color: ${({ theme, showBackground }) => (showBackground ? theme.white : theme.text1)} !important;

  ${({ showBackground }) =>
    showBackground &&
    `  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);`}
`

const TopSection = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr 120px;
  grid-gap: 0px;
  align-items: center;
  padding: 1rem;
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 48px 1fr 96px;
  `};
`

const BottomSection = styled.div<{ showBackground: boolean }>`
  padding: 12px 16px;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '0.4')};
  border-radius: 0 0 12px 12px;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  align-items: space-between;
  z-index: 1;
`

export default function PoolCard({ stakingInfo }: { stakingInfo: StakingInfo }) {
  const token0 = stakingInfo.tokens[0]
  const token1 = stakingInfo.tokens[1]

  const archived = stakingInfo.archived

  const currency0 = unwrappedToken(token0)
  const currency1 = unwrappedToken(token1)
  const maticWETHCurrency = unwrappedToken(MATIC_WETH)
  const mustCurrency = unwrappedToken(MUST)

  const isStaking = Boolean(stakingInfo.stakedAmount.greaterThan('0'))

  // get the color of the token

  const hasMustInPair = true //= currency0 === mustCurrency || currency1 === mustCurrency
  const token = currency0 === maticWETHCurrency ? token1 : token0
  const must = currency0 === mustCurrency ? token0 : token1
  const backgroundColor = useColor(token)

  const totalSupplyOfStakingToken = useTotalSupply(stakingInfo.stakedAmount.token)
  const [, stakingTokenPair] = usePair(...stakingInfo.tokens)

  // let returnOverMonth: Percent = new Percent('0')
  let valueOfTotalStakedAmountInWETH: TokenAmount | undefined
  let valueOfTotalStakedAmountInToken: TokenAmount | undefined
  if (totalSupplyOfStakingToken && stakingTokenPair) {
    // take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
    valueOfTotalStakedAmountInWETH = new TokenAmount(
      must,
      JSBI.divide(
        JSBI.multiply(
          JSBI.multiply(stakingInfo.totalStakedAmount.raw, stakingTokenPair.reserveOf(must).raw),
          JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
        ),
        totalSupplyOfStakingToken.raw
      )
    )

    valueOfTotalStakedAmountInToken = new TokenAmount(
      token,
      JSBI.divide(
        JSBI.multiply(
          JSBI.multiply(stakingInfo.totalStakedAmount.raw, stakingTokenPair.reserveOf(token).raw),
          JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
        ),
        totalSupplyOfStakingToken.raw
      )
    )
  }

  // get the USD value of staked MUST
  const USDPrice = useUSDCPrice(must)
  const USDPrice2 = useUSDCPrice(token)
  const valueOfTotalStakedAmountInUSDC =
    valueOfTotalStakedAmountInWETH &&
    valueOfTotalStakedAmountInToken &&
    (USDPrice?.quote(valueOfTotalStakedAmountInWETH) || USDPrice2?.quote(valueOfTotalStakedAmountInToken))

  return (
    <Wrapper showBackground={isStaking} bgColor={backgroundColor}>
      <CardBGImage desaturate />
      <CardNoise />

      <TopSection>
        <DoubleCurrencyLogo currency0={currency0} currency1={currency1} size={24} />
        <TYPE.white fontWeight={600} fontSize={24} style={{ marginLeft: '8px' }}>
          {currency0.symbol}-{currency1.symbol}
        </TYPE.white>

        <StyledInternalLink
          to={`/${archived ? 'archived' : 'stake'}/${currencyId(currency0)}/${currencyId(currency1)}/${
            stakingInfo.stakingRewardAddress
          }`}
          style={{ width: '100%' }}
        >
          <ButtonPrimary padding="8px" borderRadius="8px">
            {isStaking ? 'Manage' : 'Deposit'}
          </ButtonPrimary>
        </StyledInternalLink>
      </TopSection>

      <StatContainer>
        {hasMustInPair ? (
          <RowBetween>
            <TYPE.white> Total deposited</TYPE.white>
            <TYPE.white>
              {valueOfTotalStakedAmountInUSDC
                ? `$${valueOfTotalStakedAmountInUSDC.toFixed(0, { groupSeparator: ',' })}`
                : `${valueOfTotalStakedAmountInWETH?.toSignificant(4, { groupSeparator: ',' }) ?? '-'} ${must.symbol}`}
            </TYPE.white>
          </RowBetween>
        ) : (
          <></>
        )}
        <RowBetween>
          <TYPE.white> Pool rate </TYPE.white>
          <TYPE.white>
            {stakingInfo
              ? stakingInfo.active
                ? `${stakingInfo.totalRewardRates[0]
                    .multiply(BIG_INT_SECONDS_IN_WEEK)
                    ?.toFixed(1, { groupSeparator: ',' })} ${stakingInfo.earnedAmounts[0].token.symbol} / week`
                : `0 ${stakingInfo.earnedAmounts[0].token.symbol} / week`
              : '-'}
          </TYPE.white>
        </RowBetween>
        {stakingInfo &&
          stakingInfo.active &&
          stakingInfo.rewardTokens.length > 1 &&
          stakingInfo.rewardTokens.slice(1).map((rewardToken, rewardIndex) => (
            <RowBetween key={rewardIndex}>
              <TYPE.white></TYPE.white>
              <TYPE.white>{`${stakingInfo.totalRewardRates[rewardIndex + 1]
                .multiply(BIG_INT_SECONDS_IN_WEEK)
                ?.toFixed(1, { groupSeparator: ',' })} ${
                stakingInfo.earnedAmounts[rewardIndex + 1].token.symbol
              } / week`}</TYPE.white>
            </RowBetween>
          ))}
        {stakingInfo.apy ? (
          <RowBetween>
            <TYPE.white> APY </TYPE.white>
            <TYPE.white>
              {stakingInfo
                ? stakingInfo.active
                  ? `${stakingInfo.apy.multiply(JSBI.BigInt(100)).toFixed(0)} %`
                  : '0 %'
                : '-'}
            </TYPE.white>
          </RowBetween>
        ) : (
          <></>
        )}
      </StatContainer>

      {isStaking && (
        <>
          <Break />
          <BottomSection showBackground={true}>
            <RowBetween>
              <TYPE.black color={'white'} fontWeight={500}>
                <span>Your rate</span>
              </TYPE.black>

              <TYPE.black style={{ textAlign: 'right' }} color={'white'} fontWeight={500}>
                <span role="img" aria-label="wizard-icon" style={{ marginRight: '0.5rem' }}>
                  ⚡
                </span>
                {stakingInfo
                  ? stakingInfo.active
                    ? `${stakingInfo.rewardRates[0]
                        .multiply(BIG_INT_SECONDS_IN_WEEK)
                        ?.toSignificant(4, { groupSeparator: ',' })} ${stakingInfo.rewardTokens[0].symbol} / week`
                    : `0 ${stakingInfo.rewardTokens[0].symbol} / week`
                  : '-'}
              </TYPE.black>
            </RowBetween>
            {stakingInfo &&
              stakingInfo.rewardRates.slice(1).map((rewardRate, rewardIndex) => (
                <RowBetween key={rewardIndex}>
                  <TYPE.black color={'white'} fontWeight={500}></TYPE.black>
                  <TYPE.black style={{ textAlign: 'right' }} color={'white'} fontWeight={500}>
                    {stakingInfo
                      ? stakingInfo.active
                        ? `${stakingInfo.rewardRates[rewardIndex + 1]
                            .multiply(BIG_INT_SECONDS_IN_WEEK)
                            ?.toSignificant(4, { groupSeparator: ',' })} ${
                            stakingInfo.rewardTokens[rewardIndex + 1].symbol
                          } / week`
                        : `0 ${stakingInfo.rewardTokens[rewardIndex + 1].symbol} / week`
                      : '-'}
                  </TYPE.black>
                </RowBetween>
              ))}
          </BottomSection>
        </>
      )}
    </Wrapper>
  )
}
