import { AbstractConnector } from '@web3-react/abstract-connector'
import { AbstractConnectorArguments } from '@web3-react/types'

export abstract class AbstractEmailConnector extends AbstractConnector {
  public readonly supportedChainIds?: number[]

  constructor(args: AbstractConnectorArguments = {}) {
    super(args)
  }

  public abstract setEmail(email: string): void
}
